import { Discount } from '@Types/cart/Discount';
import { CartError } from './CartError';

export class DiscountError extends CartError {
  private discounts: Discount[];

  constructor(message: string, discounts: Discount[], code?: string | number) {
    super(message, code);
    this.name = 'discount_error';
    this.discounts = discounts;
  }

  public getDiscounts(): Discount[] {
    return this.discounts;
  }
}
