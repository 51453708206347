import {
  getAccount,
  changePassword,
  confirm,
  requestConfirmationEmail,
  login,
  logout,
  register,
  requestPasswordReset,
  resetPassword,
  addAddress,
  updateAddress,
  removeAddress,
  setDefaultBillingAddress,
  setDefaultShippingAddress,
} from '../../actions/account';
import { createSession } from '../../actions/adyen';
import {
  cartItems,
  addItem,
  checkout,
  orderHistory,
  removeItem,
  getPaymentMethods,
  getShippingMethods,
  getShippingMethodMapping,
  getAvailableShippingMethods,
  setPaymentMethod,
  setShippingMethod,
  updateCart,
  updateItemQuantity,
  updateItemShippingMethod,
  updateItemShippingMethods,
  refreshCart,
  redeemDiscountCode,
  removeDiscountCode,
  getProjectSettings,
  triggerPayment,
  syncCartPayment,
} from '../../actions/cart';
import { getDynamicCategoryTree } from '../../actions/navigation';
import { getSiteLinksConfiguration } from '../../actions/project';
import { getTrustbadge } from '../../actions/trustedshops';
import { getWishlist, addToWishlist, removeLineItem, updateLineItem } from '../../actions/wishlist';
import { ping, subscribe, update } from '../../actions/newsletter';
import { UseAccount } from './UseAccount';
import { UseAdyen } from './UseAdyen';
import { UseCart } from './UseCart';
import { UseNavigation } from './UseNavigation';
import { UseProject } from './UseProject';
import { UseTrustedShops } from './UseTrustedShops';
import { UseWishlist } from './UseWishlist';
import { UseNewsletter } from './UseNewsletter';

export interface FrontasticState {
  useCart: UseCart;
  useAccount: UseAccount;
  useWishlist: UseWishlist;
  useAdyen: UseAdyen;
  useNavigation: UseNavigation;
  useTrustedShops: UseTrustedShops;
  useProject: UseProject;
  useNewsletter: UseNewsletter;
}

export const getFrontasticState = (): FrontasticState => {
  return {
    useCart: {
      ...cartItems(),
      addItem,
      updateCart,
      removeItem,
      updateItemQuantity,
      getPaymentMethods,
      getShippingMethods,
      getShippingMethodMapping,
      getAvailableShippingMethods,
      setPaymentMethod,
      setShippingMethod,
      updateItemShippingMethod,
      updateItemShippingMethods,
      checkout,
      orderHistory,
      getProjectSettings,
      refreshCart,
      redeemDiscountCode,
      removeDiscountCode,
      triggerPayment,
      syncCartPayment,
    },
    useAccount: {
      ...getAccount(),
      login,
      logout,
      register,
      confirm,
      requestConfirmationEmail,
      changePassword,
      requestPasswordReset,
      resetPassword,
      update,
      addAddress,
      updateAddress,
      removeAddress,
      setDefaultBillingAddress,
      setDefaultShippingAddress,
    },
    useWishlist: {
      ...getWishlist(),
      addToWishlist,
      removeLineItem,
      updateLineItem,
    },
    useAdyen: {
      createSession,
    },
    useNavigation: {
      getDynamicCategoryTree,
    },
    useTrustedShops: {
      getTrustbadge,
    },
    useProject: {
      getSiteLinksConfiguration,
    },
    useNewsletter: {
      ping,
      subscribe,
      update,
    },
  };
};
