import GtagParameter from './interfaces/GtagParameter';
import ScriptNodeSnippets from './interfaces/ScriptNodeSnippets';

export class Snippets {
  private static readonly GTAG_DATA: GtagParameter[] = [
    {
      action: 'consent',
      name: 'default',
      data: `{
        ad_personalization: "denied",
        ad_storage: "denied",
        ad_user_data: "denied",
        analytics_storage: "denied",
        functionality_storage: "denied",
        personalization_storage: "denied",
        security_storage: "granted",
        wait_for_update: 500,
      }`,
    },
    { action: 'set', name: 'ads_data_redaction', data: `true` },
    { action: 'set', name: 'url_passthrough', data: `true` },
  ];

  public static gtag(dataLayerName: string): ScriptNodeSnippets {
    return {
      parameters: {
        'data-cookieconsent': 'ignore',
      },
      script: `
        ${Snippets.dataLayer(dataLayerName)}\r\n
        ${Snippets.gtagFunction(dataLayerName)}\r\n
        ${Snippets.GTAG_DATA.map((entry) => Snippets.gtagCall(entry)).join('\r\n')}
      `,
    };
  }

  public static cmp(cbid: string): ScriptNodeSnippets {
    return {
      parameters: {
        type: `text/javascript`,
        src: `https://consent.cookiebot.com/uc.js`,
        'data-cbid': cbid,
        'data-blockingmode': `auto`,
        'data-consentmode-defaults': `disabled`,
      },
    };
  }

  private static dataLayer(dataLayerName: string): string {
    return `window.${dataLayerName} = window.${dataLayerName} || [];`;
  }

  private static gtagFunction(dataLayerName: string): string {
    return `
      function gtag() { 
        ${dataLayerName}.push(arguments);
      }`;
  }

  private static gtagCall(param: GtagParameter): string {
    return `gtag("${param.action}", "${param.name}", ${param.data});`;
  }
}
