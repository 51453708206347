import { Cart } from '@Types/cart/Cart';
import { LineItem } from '@Types/cart/LineItem';
import { Money } from '@Types/product/Money';
import { Product } from '@Types/product/Product';
import { Variant } from '@Types/product/Variant';
import { DataLayer } from '@Types/tagmanager/DataLayer';
import { EcommerceDataLayerItem } from '@Types/tagmanager/EcommerceDataLayerItem';
import { Transaction } from '@Types/cart/Transaction';

export class DataLayerFactory {
  static readonly EVENT_VIEW_ITEM = 'view_item';
  static readonly EVENT_ADD_TO_CART = 'add_to_cart';
  static readonly EVENT_BEGIN_CHECKOUT = 'begin_checkout';
  static readonly EVENT_PURCHASE = 'purchase';

  static readonly ATTRIBUTE_BRAND = 'brand';

  static getViewItemLayer(product: Product, variant: Variant, payload?: any): DataLayer {
    payload = payload ?? {};

    return {
      event: DataLayerFactory.EVENT_VIEW_ITEM,
      ecommerce: {
        currency: variant.price?.currencyCode ?? 'EUR',
        value: DataLayerFactory.convertCentAmount({
          currencyCode: variant.price?.currencyCode ?? 'EUR',
          centAmount: variant.price?.centAmount,
          fractionDigits: variant.price?.fractionDigits ?? 2,
        }),
        items: [DataLayerFactory.getVariantItemForEcomLayer(product, variant, 0)],
        ...payload,
      },
    };
  }

  static getAddToCartLayer(product: Product, variant: Variant, quantity?: number, payload?: any): DataLayer {
    payload = payload ?? {};

    return {
      event: DataLayerFactory.EVENT_ADD_TO_CART,
      ecommerce: {
        currency: variant.price?.currencyCode ?? 'EUR',
        value: DataLayerFactory.convertCentAmount({
          currencyCode: variant.price?.currencyCode ?? 'EUR',
          centAmount: Math.max(1, quantity ?? 0) * (variant.price?.centAmount ?? 0),
          fractionDigits: variant.price?.fractionDigits ?? 2,
        }),
        items: [DataLayerFactory.getVariantItemForEcomLayer(product, variant, quantity ?? 0)],
        ...payload,
      },
    };
  }

  static getBeginCheckoutLayer(cart: Cart): DataLayer {
    return DataLayerFactory.getCartLayer(cart, DataLayerFactory.EVENT_BEGIN_CHECKOUT);
  }

  static getPurchaseLayer(cart: Cart): DataLayer {
    const dataLayer = DataLayerFactory.getCartLayer(cart, DataLayerFactory.EVENT_PURCHASE);
    const discountCodes =
      cart.discountCodes
        ?.filter((discount) => !!discount.code && discount.discountedAmount > 0)
        ?.map((discount) => discount.code) ?? [];

    dataLayer.ecommerce.transaction_id = DataLayerFactory.getTransactionId(cart);
    dataLayer.ecommerce.shipping = DataLayerFactory.convertCentAmount(DataLayerFactory.getCartShippingCosts(cart));
    dataLayer.ecommerce.coupon = discountCodes.length ? discountCodes.shift() : undefined;

    return dataLayer;
  }

  private static getCartLayer(cart: Cart, event: string): DataLayer {
    return {
      event,
      ecommerce: {
        currency: cart.sum?.currencyCode ?? 'EUR',
        value: DataLayerFactory.convertCentAmount(cart.sum),
        items: DataLayerFactory.getCartItemsForEcomLayer(cart.lineItems),
      },
    };
  }

  static getCustomEventLayer(event: string, payload?: any): DataLayer {
    return {
      event: event,
      ...payload,
    };
  }

  private static getCartShippingCosts(cart: Cart): Money | undefined {
    if (cart.shippingMode !== 'Multiple') {
      return cart.shippingInfo?.price;
    }

    const shippingTotal: Money = cart.lineItems?.reduce((shippingCosts: Money, lineItem: LineItem) => {
      if (lineItem.shippingDetails?.price) {
        shippingCosts.currencyCode = shippingCosts.currencyCode || lineItem.shippingDetails.price.currencyCode;
        shippingCosts.fractionDigits = shippingCosts.fractionDigits || lineItem.shippingDetails.price.fractionDigits;
        shippingCosts.centAmount = (shippingCosts.centAmount ?? 0) + (lineItem.shippingDetails.price?.centAmount ?? 0);
      }

      return shippingCosts;
    }, {});

    return shippingTotal.centAmount && shippingTotal.centAmount > 0 ? shippingTotal : undefined;
  }

  private static getTransactionId(cart: Cart): string | undefined {
    const payment = cart.payments?.find(
      (payment) =>
        !!payment.transactions.find(
          (transaction) =>
            DataLayerFactory.isPaymentTransaction(transaction) && DataLayerFactory.isValidLayerTransaction(transaction),
        ),
    );

    return !!payment
      ? payment.transactions.find((transaction) => DataLayerFactory.isValidLayerTransaction(transaction)).transactionId
      : undefined;
  }

  private static convertCentAmount(price: Money | undefined): number {
    const fractionDigits = price?.fractionDigits ?? 2;
    return (price?.centAmount ?? 0) / 10 ** fractionDigits;
  }

  // @todo - code duplication, see TrackingDataMapper::getVariantItemForEcomLayer() in backend
  private static getVariantItemForEcomLayer(
    product: Product,
    variant: Variant,
    quantity: number,
  ): EcommerceDataLayerItem {
    const ecomLayerItem: EcommerceDataLayerItem = {
      item_id: variant.sku,
      item_name: product.name,
      item_brand: DataLayerFactory.getAttributeValue(variant, DataLayerFactory.ATTRIBUTE_BRAND),
      price: DataLayerFactory.convertCentAmount(variant.price),
      quantity: quantity > 0 ? quantity : undefined,
      item_category: '',
    };

    const breadcrumbs = (product.categories?.[0]?.breadcrumbs ?? []).slice(0, 4);
    breadcrumbs.forEach((breadcrumb, index) => {
      ecomLayerItem[`item_category${index ? index + 1 : ''}`] = breadcrumb.name;
    });

    return ecomLayerItem;
  }

  private static getCartItemsForEcomLayer(cartItems: LineItem[]): EcommerceDataLayerItem[] {
    return (
      cartItems
        .filter((cartItem) => cartItem.dataLayer && this.isEcommerceDataLayerItem(cartItem.dataLayer))
        .map((cartItem) => cartItem.dataLayer) ?? []
    );
  }

  private static isEcommerceDataLayerItem(data: any): data is EcommerceDataLayerItem {
    return (
      (data as EcommerceDataLayerItem).item_id !== undefined &&
      (data as EcommerceDataLayerItem).item_category !== undefined &&
      (data as EcommerceDataLayerItem).price !== undefined
    );
  }

  private static getAttributeValue(variant: Variant, attributeName: string, defaultValue?: string): string {
    const value = variant.attributes?.[attributeName] ?? defaultValue;
    return Array.isArray(value) ? value.join(',') : value;
  }

  private static isPaymentTransaction(data: any): data is Transaction {
    return (data as Transaction).transactionId !== undefined;
  }

  private static isValidLayerTransaction(transaction: Transaction): boolean {
    return (
      (transaction.transactionState === 'Pending' || transaction.transactionState === 'Success') &&
      (transaction.transactionType === 'Authorization' || transaction.transactionType === 'Charge')
    );
  }
}

export default DataLayerFactory;
