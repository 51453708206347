import { fetchApiHub } from 'frontastic';

export const ping = async () => {
  return await fetchApiHub('/action/newsletter/ping', { method: 'POST' });
};

export const subscribe = async (data) => {
  return await fetchApiHub('/action/newsletter/subscribe', { method: 'POST' }, data);
};

export const update = async (data) => {
  return await fetchApiHub('/action/newsletter/update', { method: 'POST' }, data);
};
