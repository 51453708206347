import { fetchApiHub } from '../../lib/fetch-api-hub';
import { mutate } from 'swr';

export const getTrustbadge = async () => {
  const response = await fetchApiHub('/action/trustedshops/getTrustbadge');
  const trustedbadge = response.trustbadge?.trustedShopsId || response.trustedShopsId
    ? response.trustbadge ?? response
    : null;

  await mutate('/action/trustedshops/getTrustbadge', trustedbadge);
  return trustedbadge;
};
