import { CheckoutResponseError } from './CheckoutResponseError';

export class PaymentResponseError extends CheckoutResponseError {
  public readonly field?: string;

  constructor(message: string, code?: string | number, field?: string) {
    super(message, code);
    this.name = 'payment_error';
    this.field = field;
  }
}
